import {addDays, addYears, subMonths, subYears, startOfYear, startOfMonth, differenceInCalendarDays, differenceInYears, format, parseISO, parse} from 'date-fns'

const dateStringFromDate = (date, formatString) => {
    return format(date, formatString);
};

const dateStringWithFormat = (date, formatString) => {
    return format(parseISO(date), formatString);
};

const calculateAgeAsOfToday = (dateOfBirth, format) => {
    return differenceInYears(new Date(), parse(dateOfBirth, format, new Date()));
};

const differenceBetweenDatesInYears = (date1, date2) => {
    return differenceInYears(parseISO(date1), parseISO(date2));
};

const differenceBetweenDatesInYearsWithFormat = (date1, date2, format) => {
    return differenceInYears(parse(date1, format, new Date()), parse(date2, format, new Date()));
};

const differenceBetweenDatesInDays = (date1, date2) => {
    return differenceInCalendarDays(parseISO(date1), parseISO(date2));
};

const dateStringAsOfToday = (formatString) => {
    return format(new Date(), formatString);
};

const dateStringNDaysfromToday = (offset, formatString) => {
    return format(addDays(new Date(), offset), formatString);
};

const addYearsToGivenDate = (dateOfBirth, yearsToadd, formatString) => {
    return addYears(dateObjectFromString(dateOfBirth, formatString), yearsToadd);
};

const addDaysToGivenDate = (date, daysToadd) => {
    return addDays(date, daysToadd);   
};

const dateFirstDayOfTheYear = (offset) => {
    return subYears(startOfYear(new Date()), offset);
};

const dateFirstDayOfTheMonth = (offset) => {
    return subMonths(startOfMonth(new Date()), offset);
};

const dateObjectFromString = (dateString, format) => {
    return parse(dateString, format, new Date())
}

const appendTimeOffsetIfNeeded = (dateString) => {
    if (!dateString) return dateString;
    return dateString.includes('T') || dateString.includes(' ') ? dateString : `${dateString}T11:30:00`;
};

export { addYearsToGivenDate, calculateAgeAsOfToday, differenceBetweenDatesInYears, dateStringAsOfToday, dateStringNDaysfromToday, dateFirstDayOfTheYear, dateFirstDayOfTheMonth, dateStringFromDate, dateStringWithFormat, dateObjectFromString, differenceBetweenDatesInYearsWithFormat, addDaysToGivenDate, differenceBetweenDatesInDays, appendTimeOffsetIfNeeded }